import React, { useState } from "react";
import { BsChatDots } from "react-icons/bs"; // Chat icon
import ChatbotPopup from "../ChatbotPopup/ChatbotPopup";

const ChatFloating: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false); // Explicitly typed state

  return (
    <>
      <button
        className="fixed bottom-5 right-5 flex items-center bg-teal-600 text-white py-2 px-4 rounded-full shadow-md cursor-pointer text-sm font-medium transition ease-in-out duration-300 hover:bg-teal-700 hover:shadow-lg"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className="mr-2">Intelligent Companion</span>
        <BsChatDots size={20} className="flex" />
      </button>
      {isOpen && <ChatbotPopup onClose={() => setIsOpen(false)} />}
    </>
  );
};

export default ChatFloating;

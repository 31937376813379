import axios from "axios";

const API_URL = "https://rag-ai-backend.iryscloud-dev.com/api/v1/rag-ai/conversation/start";

export const fetchBotResponse = async (userMessage: string, previousMessages: any[] = []) => {
  try {
    const payload = {
      data: [
        {
          conversation: userMessage,
          created_at: new Date().toISOString(),
          activity_type: "rag-ai",
          previous_conversation: previousMessages.map((msg) => ({
            conversation: msg.text,
            created_at: msg.timestamp,
            id: msg.id || "random-id",
          })),
        },
      ],
    };

    const response = await axios.post(API_URL, payload);
    // const response = await axios.post('/api/v1/rag-ai/conversation/start', payload);
    console.log("API Response:", JSON.stringify(response.data, null, 2));

    if (
      response.data?.response &&
      Array.isArray(response.data.response) &&
      response.data.response[0]?.result &&
      Array.isArray(response.data.response[0].result)
    ) {
      const botResponse = response.data.response[0].result[0]?.conversation_response;
      if (botResponse) return botResponse;
    }

    throw new Error(`Unexpected API response: ${JSON.stringify(response.data)}`);
  } catch (error: any) {
    console.error("Error fetching bot response:", error.message);
    return "Error: Unable to fetch response.";
  }
};

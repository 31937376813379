

import React, { useState, useEffect, useRef , useCallback} from 'react';
import {Tooltip, Snackbar,Alert} from "@mui/material";
import { BiRefresh } from 'react-icons/bi';
import { BsRobot } from "react-icons/bs"; // Bot icon
import { MdSend } from "react-icons/md"; // Send icon
import IrysLogo from "../../assets/irys.png";
import WebSocketClientService from "../../api/websocketService";
import log from "../../logging";
import * as CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import DOMPurify from "dompurify";




function stringToUUIDv4(str :any) {
  // Create a hash of the string (you could use any hashing function, such as SHA-1 or SHA-256)
  const hash = CryptoJS.SHA1(str).toString(CryptoJS.enc.Hex);


  // Use the hash to generate a version 4 UUID
  let uuid = hash.substring(0, 8) + '-' +        
             hash.substring(8, 12) + '-' +        
             '4' + hash.substring(13, 16) + '-' +
             hash.substring(16, 20) + '-' +      
             hash.substring(20, 32);            


  return uuid;
}


const formatText = (text: string): string => {
  // Convert **text** to bold
  text = text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");


  // Convert "*/n" into bullet points and ensure new line after it
  text = text.replace(/^\*\s*(.+)$/gm, "• $1");


  //Replace general new lines with <br /> (if not part of */n)
  text = text.replace(/\n/g, "<br />");
 
  // Wrap all <li> items with <ul> if any bullet points exist
  if (text.includes("<li>")) {
    text = `<ul>${text}</ul>`;
  }






  return DOMPurify.sanitize(text); // Sanitize for security
};


const TextFormatter: React.FC<{ text: string }> = ({ text }) => {
  return <div dangerouslySetInnerHTML={{ __html: formatText(text) }} />;
};


interface ChatbotPopupProps {
  onClose: () => void;
}


interface Message {
  sender: "user" | "bot";
  text: string;
  timestamp: string;
  buttonFlag: boolean;
  id?: string; // Optional ID for context
}


// Define interface for auth details
interface AuthDetails {
  irysUserId: string;
  userTenantId: string;
  entityTenantId: string;
  entityReferenceId: string;
  eventSessionId:string;
}


interface ButtonIdToFileRef {
  Id: number;
  fileRef: string[];
}


const webSocketService = new WebSocketClientService();


const ChatbotPopup: React.FC<ChatbotPopupProps> = ({ onClose}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [previousConversation, setPreviousConversation] = useState<any[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [pendingMessages, setPendingMessages] = useState<string[]>([]); // Array to store messages typed before connection
  const [openSnackbar,setOpenSnackbar] = useState(false);
  const [fileId, setFileId] = useState<string[]>([]);
  const [value, setButtonIdToFileRef] = useState<ButtonIdToFileRef[]>([]);


  //const [authDetails, setAuthDetails] = useState<AuthDetails | null>(null);
  const [authDetails, setAuthDetails] = useState({
    irysUserId: '',
    userTenantId: '',
    entityTenantId: '',
    entityReferenceId: '',
    eventSessionId:''
  });




    const handleResponse = useCallback((data: any) => {
    try {
      if (!data || !data.response) {
        log.error('No response data received from WebSocket');
        throw new Error('No response received from server');
      }
 
      if (!data.response?.[0]?.result?.[0]?.conversation_response) {
        throw new Error('Invalid response format');
      }
 
      const response = data.response[0].result[0].conversation_response;
      const file_ref = data.response[0].result[0].file_reference_id;
      console.log(response,file_ref)
      if (typeof response !== 'string' || response.length === 0) {
        throw new Error('Invalid response content');
      }
     
      setMessages(prev => prev.slice(0, -1).concat({
        sender: 'bot',
        text: response,
        timestamp: new Date().toLocaleTimeString(),
        buttonFlag: file_ref.length > 0? true : false
      }));
 
      setFileId(file_ref);
      setPreviousConversation(data.response[0].prev_conversation);
 
      //log.debug("API Request Payload:", JSON.stringify(data, null, 2));
    log.debug("API Response:", JSON.stringify(data, null, 2));
    console.log('Bot response successfully extracted:',data.response[0].result[0]?.conversation_response)
    console.log('Previous Conversation:',data.response[0].prev_conversation)
 
    } catch (error) {
      console.log('Response handling error');
      setMessages(prev => [...prev.slice(0, -1), {
        sender: 'bot',
        text: 'Unable to fetch response. Please try again.',
        timestamp: new Date().toLocaleTimeString(),
        buttonFlag: false
      }]);
      log.error('Response handling error:', error);
    } finally {
      setIsProcessing(false);
    // Focus on input after processing is complete
    if (inputRef.current) {
      inputRef.current.focus();
    }
    }
  }, []); // Empty dependency array


  const handleClearContext = () => {
    setInput(''); // clear input field
    setPreviousConversation([]); // clear previous conversation
    setOpenSnackbar(true); //show success notification
    // setMessages([{
    //   sender: "bot",
    //   text: "Hi! I'm your IRYS companion. How can I help you today?",
    //   timestamp: new Date().toLocaleTimeString([], { hour: "numeric", minute: "2-digit", second:"2-digit" }),
     
    // }]); // reset to initial welcome message
  };




  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  useEffect(() => {
    if (inputRef.current) {
    inputRef.current.focus();
    }
    }, []);


  useEffect(() => {
    const loadAuthData = () => {
      try {
       
        const ssoTokenCookie = Cookies.get('ssoToken');
        const userDataCookie = Cookies.get('auth');
       // console.log(ssoTokenCookie,userDataCookie);
        Cookies.remove('ssoToken');
        Cookies.remove('auth');
        if (userDataCookie) {
          const parsedData = JSON.parse(userDataCookie);
         // console.log(parsedData.auth);
          setAuthDetails({
            irysUserId: parsedData.auth.irys_User_Id,
            userTenantId: parsedData.auth.user_Tenant_Id,
            entityTenantId: parsedData.auth.entity_Tenant_Id,
            entityReferenceId: parsedData.auth.entity_Reference_Id,
            eventSessionId: stringToUUIDv4(ssoTokenCookie),
           
          } as AuthDetails);
        }
      } catch (error) {
        console.error('Error parsing cookie data:', error);
      }
    };


    loadAuthData();
  }, []);


  useEffect(() => {
    scrollToBottom();
  }, [messages]);
 


  // Initialize chat with a welcome message
  useEffect(() => {
    const welcomeMessage: Message = {
      sender: "bot",
      text: "Hi! I'm your IRYS companion. How can I help you today?",
      timestamp: new Date().toLocaleTimeString([], { hour: "numeric", minute: "2-digit", second:"2-digit" }),
      buttonFlag: false
    };
    setMessages([welcomeMessage]);
     // Initialize WebSocket and set up subscriptions
     webSocketService.initialize()
     .then(() => {
       setIsConnected(true);
       webSocketService.addListener(handleResponse); // Re-add listener
     })
     .catch((error) => {
       setIsConnected(false);
       setMessages(prev => [...prev, {
         sender: 'bot',
         text: 'Failed to connect. Please refresh the page.',
         timestamp: new Date().toLocaleTimeString(),
         buttonFlag: false
       }]);
       console.error('Failed to initialize WebSocket:', error.message);
     });


  //handle responses and logs
  webSocketService.addErrorListener((error) => {
    setMessages(prev => [...prev, {
      sender: 'bot',
      text: error,
      timestamp: new Date().toLocaleTimeString(),
      buttonFlag: false
    }]);
  });
 
 


 
  webSocketService.addListener(handleResponse);
      // Cleanup on component unmount
      return () => {
        webSocketService.client?.disconnect();
        console.log('WebSocket connection closed');
      };
  }, [handleResponse]);




  // Handle sending a message
    const handleSendMessage = useCallback(async (message: string) => {
    //const handleSendMessage = async (message: string) => {
    if (!input.trim()) return;
    if (!authDetails) return;
    setIsProcessing(true);
     // If WebSocket is not connected, store the message
     if (!isConnected) {
      setPendingMessages((prev) => [...prev, message]);
      return;
    }
   




    // Add user message
    const userMessage: Message = {
      sender: "user",
      text: input,
      timestamp: new Date().toLocaleTimeString([], { hour: "numeric", minute: "2-digit", second:"2-digit" }),
      buttonFlag: false
    };
    setMessages((prev) => [...prev, userMessage]);


    const eventData = {
      "sourceNumber": 1,
      "metadata": {
        "eventType": "RAG_AI_Backend_Operation"
      },
      "payload": {
        "records": [
          {
            "eventVersion": "0.1",
            "eventSource": "RAG_AI_Service",
            "eventTime": "2025-01-22T14:33:26.308Z",
            "eventName": "RAG_AI_Retrieval",
            "eventUserId": authDetails.irysUserId,
            "tenantId": authDetails.userTenantId,
            "entityTenantId": authDetails.entityTenantId,
            "entityReferenceId": authDetails.entityReferenceId,
            "eventSessionId": authDetails.eventSessionId,
            "eventMessage": {
              "info": {
                "question": input,
                "prev_conversation": previousConversation,
                "metadata": {
                  "dataSize": "",
                  "retryCount": "0",
                  "activityType": "ragAiBackendOperation",
                  "trackingId": ""
                }
              }
            },
            "eventError": {}
          }
        ]
      }
    };


       // @ts-ignore: Object is possibly 'null'.
    webSocketService.publishMessage(eventData);
    // Clear input field
    setInput("");


    // Add temporary "Typing..." bot message
    const typingMessage: Message = {
      sender: "bot",
      text: "Typing...",
      timestamp: "",
      buttonFlag: false
    };
    setMessages((prev) => [...prev, typingMessage]);


   
},[authDetails, isConnected, previousConversation, input]);


const checkOnClick = (id: number) => {
  console.log(id)
  let foundItem = value.find(item => item.Id === id)
  if(!foundItem) {
    createButtonId(id)
    foundItem = value.find(item => item.Id === id)
  }
  const event = new CustomEvent("transferGUIDs", {
    detail: {guids: foundItem?.fileRef},
  });
  window.dispatchEvent(event);
  console.log(fileId)
}


const createButtonId = (index: number) => {
  const valuOb: ButtonIdToFileRef = {
    Id: index,
    fileRef: fileId
  }
  value.push(valuOb)
  console.log(value)
  setButtonIdToFileRef(value)
  return valuOb.Id
}


 // Timeout logic in a useEffect hook
 useEffect(() => {
  let timeoutId: NodeJS.Timeout | null = null;


  if (isProcessing) {
    timeoutId = setTimeout(() => {
      // If no response is received within the timeout, display an error message
      setMessages((prev) => [
        ...prev.slice(0, -1), // Remove the "Typing..." message
        {
          sender: "bot",
          text: "No response from the server. Please try again later.",
          timestamp: new Date().toLocaleTimeString(),
          buttonFlag: false
        },
      ]);
      setIsProcessing(false);
    }, 20000); // Adjust the timeout value as needed
  } else if (timeoutId) {
    clearTimeout(timeoutId);
  }


  return () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };
}, [isProcessing]);


useEffect(() => {
  if (isConnected && pendingMessages.length > 0) {
    pendingMessages.forEach((message) => {
      if (message.trim()) {
        handleSendMessage(message);
      }
    });
    setPendingMessages([]);
  }
}, [isConnected, pendingMessages, handleSendMessage]);


  return (
   
      <div className="fixed bottom-4 right-4 bg-white w-80 h-[530px] shadow-lg border border-gray-300 rounded-2xl flex flex-col overflow-hidden"  style={{ zIndex: 999 }}>
      {/* Header */}
      <div className="flex justify-between items-center bg-white px-5 py-3 border-b border-gray-200 rounded-t-2xl">
        <div className="flex items-center gap-2">
          <button className="text-gray-600 text-lg" onClick={onClose}>
            ←
          </button>
          <img src={IrysLogo} alt="IRYS Logo" className="w-10 h-10 rounded-full" />
          <div>
            <h2 className="text-sm font-semibold text-blue-600">Intelligent Companion</h2>
            <div className="flex items-center gap-1">
              <span className="w-2 h-2 bg-green-500 rounded-full shadow-md"></span>
              <span className="text-xs font-medium text-green-500">Online</span>
            </div>
          </div>
        </div>
        <button className="text-gray-500 text-xl cursor-pointer" onClick={onClose}>
          ✕
        </button>
      </div>


      {/* Messages */}
      <div className="flex-1 overflow-y-auto space-y-1 p-2 pl-4">
        {messages.map((msg, index) => (
         
       
          <div
            key={index}
            className={`flex ${msg.sender === "user" ? "justify-end" : "justify-start"}`}
          >      
            <div
           
              className={`relative px-3 py-1 rounded-lg text-sm max-w-[75%] ${
                msg.sender === "bot"
                  ? "bg-gray-100 text-gray-800 shadow-sm"
                  : "bg-teal-600 text-white hover:bg-teal-700 transition duration-300 ease-in-out"
              }`}
            >
           
            <TextFormatter text={msg.text}/>
            {
  (msg.buttonFlag) && (
    <a 
      href="/about" 
      onClick={(e) => {
        e.preventDefault(); // Prevent default link behavior
        checkOnClick(index); 
      }} 
      className="text-teal-600 hover:text-teal-700 font-semibold underline transition duration-150 ease-in-out" 
    >
      Click Here
    </a>
  )
}
              <div
                className={`text-xs mt-1 leading-none ${
                  msg.sender === "bot" ? "text-gray-500" : "text-gray-200 text-right"
                }`}
              >
                {msg.timestamp}
              </div>
              {msg.sender === "bot" && (
                <div className="absolute -bottom-2 -left-3 w-6 h-6 flex items-center justify-center bg-blue-100 border border-blue-300 rounded-full shadow-sm">
                  <BsRobot size={14} className="text-blue-600" />
                </div>
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>


      {/* Input */}
     
      <div className="flex items-center px-4 py-3 border-t border-gray-200 bg-gray-50">
        <input
          ref={inputRef}
          type="text"
          className="flex-1 p-3 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
          placeholder="Write your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
         // onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
          onKeyDown={(e) => {
            if(e.key === 'Enter' && !isProcessing) {
            //  handleSendMessage()
              handleSendMessage(input); // Pass 'input' as an argument
            }
          }}
        />


          {/* Clear Context button */}
          <Tooltip title ="Clear Context">
         <button onClick={handleClearContext} className="ml-3 p-2 rounded-full bg-gray-200 hover:bg-gray-300">
          <BiRefresh size={20} />
         </button>
         </Tooltip>


        {/* snackbar button */}
        <div style={{position:'relative' , width:'100%', height:'100%'}}>
        <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={2000}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        style={{position:'absolute',transform:'translateX(-80%)'}}
        >
       <Alert onClose={() => setOpenSnackbar(false)} severity='success' variant='filled'
        sx={{
        display:'flex',
        alignItems:'center',
        whiteSpace:'nowrap',
        minWidth:'250px',
        justifyContent:'center'


        }}
       >
       Previous Context cleared
       </Alert>
       </Snackbar>
       </div>


     
        <button
          className={`text-white p-3 rounded-full ml-3 flex justify-center items-center w-10 h-10
            ${isProcessing ? 'bg-blue-300' : 'bg-blue-600'}`}
         // onClick={handleSendMessage}
         onClick={() => handleSendMessage(input)}
          disabled={isProcessing}
        >
          <MdSend size={20} />
        </button>
      </div>
    </div>
  );
};


export default ChatbotPopup;

import React, { useState, useEffect } from "react";
import { BsRobot } from "react-icons/bs"; // Bot icon
import { MdSend } from "react-icons/md"; // Send icon
import IrysLogo from "../../assets/irys.png";
import { fetchBotResponse } from "../../api/apiService"; // API function

interface ChatbotPopupProps {
  onClose: () => void;
}

interface Message {
  sender: "user" | "bot";
  text: string;
  timestamp: string;
  id?: string; // Optional ID for context
}

const ChatbotPopup: React.FC<ChatbotPopupProps> = ({ onClose }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");

  // Initialize chat with a welcome message
  useEffect(() => {
    const welcomeMessage: Message = {
      sender: "bot",
      text: "Hi! I'm your IRYS companion. How can I help you today?",
      timestamp: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
    };
    setMessages([welcomeMessage]);
  }, []);

  // Handle sending a message
  const handleSendMessage = async () => {
    if (!input.trim()) return;

    // Add user message
    const userMessage: Message = {
      sender: "user",
      text: input,
      timestamp: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
    };
    setMessages((prev) => [...prev, userMessage]);

    // Clear input field
    setInput("");

    // Add temporary "Typing..." bot message
    const typingMessage: Message = {
      sender: "bot",
      text: "Typing...",
      timestamp: "",
    };
    setMessages((prev) => [...prev, typingMessage]);

    try {
      // Fetch bot response
      const botReply = await fetchBotResponse(input, messages);

      // Update chat with bot's response
      setMessages((prev) =>
        prev.slice(0, -1).concat({
          sender: "bot",
          text: botReply,
          timestamp: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
        })
      );
    } catch (error) {
      console.error("Error while fetching bot reply:", error);

      // Show error message
      setMessages((prev) =>
        prev.slice(0, -1).concat({
          sender: "bot",
          text: "Something went wrong. Please try again later.",
          timestamp: new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
        })
      );
    }
  };

  return (
    <div className="fixed bottom-4 right-4 bg-white w-80 h-[500px] shadow-lg border border-gray-300 rounded-2xl flex flex-col overflow-hidden">
      {/* Header */}
      <div className="flex justify-between items-center bg-white px-4 py-3 border-b border-gray-200 rounded-t-2xl">
        <div className="flex items-center gap-2">
          <button className="text-gray-600 text-lg" onClick={onClose}>
            ←
          </button>
          <img src={IrysLogo} alt="IRYS Logo" className="w-10 h-10 rounded-full" />
          <div>
            <h2 className="text-sm font-semibold text-blue-600">Intelligent Companion</h2>
            <div className="flex items-center gap-1">
              <span className="w-2 h-2 bg-green-500 rounded-full shadow-md"></span>
              <span className="text-xs font-medium text-green-500">Online</span>
            </div>
          </div>
        </div>
        <button className="text-gray-500 text-xl cursor-pointer" onClick={onClose}>
          ✕
        </button>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-2">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`flex ${msg.sender === "user" ? "justify-end" : "justify-start"}`}
          >
            <div
              className={`relative px-3 py-2 rounded-lg text-sm max-w-[75%] ${
                msg.sender === "bot"
                  ? "bg-gray-100 text-gray-800 shadow-sm"
                  : "bg-teal-600 text-white hover:bg-teal-700 transition duration-300 ease-in-out"
              }`}
            >
              <span className="leading-tight break-words">{msg.text}</span>
              <div
                className={`text-xs mt-1 leading-none ${
                  msg.sender === "bot" ? "text-gray-500" : "text-gray-200 text-right"
                }`}
              >
                {msg.timestamp}
              </div>
              {msg.sender === "bot" && (
                <div className="absolute -bottom-2 -left-2 w-6 h-6 flex items-center justify-center bg-blue-100 border border-blue-300 rounded-full shadow-sm">
                  <BsRobot size={14} className="text-blue-600" />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Input */}
      <div className="flex items-center px-4 py-3 border-t border-gray-200 bg-gray-50">
        <input
          type="text"
          className="flex-1 p-3 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-400"
          placeholder="Write your message..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <button
          className="bg-blue-600 text-white p-3 rounded-full ml-3 flex justify-center items-center w-10 h-10"
          onClick={handleSendMessage}
        >
          <MdSend size={20} />
        </button>
      </div>
    </div>
  );
};

export default ChatbotPopup;

import { io, Socket } from 'socket.io-client';



interface EventData {
  sourceNumber: number;
  payload: {
    records: Array<{
      eventVersion: string;
      eventSource: string;
      eventTime: string;
      eventName: string;
      eventType: string;
      eventMessage: {
        info: {
          referenceId: string;
          documentId: string;
          namespaceName: string;
          meta: {
            dataSize: string;
            retryCount: string;
            activityType: string;
            trackingId: string;
          };
        };
      };
      eventError: Record<string, unknown>;
    }>;
  };
}



export default class WebSocketClientService {
  public cleanup(): void {
    // Clear ping interval
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  
    // Remove all event listeners
    if (this.client) {
      this.client.removeAllListeners();
      this.client.disconnect();
      this.client = null;
    }
  
    // Reset all state
    this.trackingId = null;
    this.reconnectAttempts = 0;
    this.totalReconnectCount = 0;
    this.isInitialConnection = true;
    this.messageQueue = [];
    this.listeners = [];this.reconnectDelay = 1000;
  }

  
  
  public client: Socket | null = null;
  private trackingId: string | null = null;
  private pingInterval: NodeJS.Timeout | null = null;
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 10; // Adjust as needed
 // private reconnectTimeout: number = 5000; // Adjust as needed
  private isInitialConnection = true;
  private totalReconnectCount: number = 0; // Track total reconnections
  private maxTotalReconnects: number = 10; // Limit for total reconnections
  private reconnectDelay = 1000; // Initial delay in milliseconds
  private maxReconnectDelay = 60000; // Maximum delay in milliseconds
  private messageQueue: EventData[] = [];
  private isDisconnectedPermanently = false;
  
  
  //public listeners : Array<any>=[]
  private listeners: Array<(any:any) => void> = [];
  /**
   * Initializes the WebSocket connection.
   * @returns {Promise<Socket>} Resolves to the WebSocket client.
   */
  private queueMessage(message: EventData) {
    this.messageQueue.push(message);
  }
  private processMessageQueue() {
    while(this.messageQueue.length > 0 && this.client?.connected) {
      const message = this.messageQueue.shift();
      if(message) {
        this.publishMessage(message);
      }
    }
  }
  private handleReconnect() {
    this.reattachListeners();
    this.resubscribeToEvents();
  }

  private resubscribeToEvents() {
    if (this.trackingId && this.client) {
      this.client.emit('subscribe', {
        apiKey: process.env.REACT_APP_PUBLISH_AND_SUBSCRIBE_API_KEY,
        event: 'RAG_AI_Backend_Operation',
        trackingId: this.trackingId
      });
    }
  }
  private reattachListeners() {
    console.log('Reattaching listeners...');
    if (this.client) {
      this.client.removeAllListeners('RAG_AI_Backend_Operation');
      this.client.on('RAG_AI_Backend_Operation', (payload: any) => {
        if (this.trackingId && payload.trackingId === this.trackingId) {
          this.listeners.forEach((callback) => callback(payload));
        }
      });
      console.log('Listeners reattached successfully');
    }
  }

  initialize(): Promise<Socket> {
    return new Promise((resolve, reject) => {
      if (this.client && this.client.connected) {
        console.log('WebSocket is already connected');
        return resolve(this.client);
      }
      try {
        
        this.client = io(process.env.REACT_APP_WEBSOCKET_URL, {
          transportOptions: {
            polling: {
              extraHeaders: {
                Authorization:  process.env.REACT_APP_WEBSOCKET_AUTH_TOKEN,               
              },
            },
          },
          query: {
            id: '12345',
            name: 'John Doe',
          },

          
        });

        
        this.client.on('connect', () => {
          console.log('Connected to WebSocket server');
         // this.reconnectAttempts = 0; // Reset reconnect attempts on successful connection
          if (!this.isInitialConnection) {
            this.reattachListeners();
            this.processMessageQueue();
        }
        this.isInitialConnection = false;
           
             // Start a timer to send pings every 5 minutes (adjust as needed)
             this.pingInterval = setInterval(() => {
              this.client?.emit('ping'); // Send a ping message
            }, 120000); // 2 minutes in milliseconds

          if (this.client) {
            resolve(this.client); // Ensures `this.client` is non-null
          }
        });

        

        this.client.on('pong', () => {
          console.log('Received pong from server');
          // Reset the ping timer when a pong is received
          if (this.pingInterval) {
            clearInterval(this.pingInterval);
            this.pingInterval = setInterval(() => {
              this.client?.emit('ping');
            }, 120000);
          }
        });
        
 
        this.client.on('connect-error', (error: Error) => {
          console.error('WebSocket connection error:', error.message);
          this.emitError('Network connection issue. Please contact Admin or try again.');
          reject(error);
        });

        


        this.client.on('subscription-success', (message) => {
          console.log('subscription-success:', message);
          this.client?.emit('RAG_AI_Backend_Operation', {
            apiKey: process.env.REACT_APP_PUBLISH_AND_SUBSCRIBE_API_KEY,
            event: 'RAG_AI_Backend_Operation ',
            trackingId: this.trackingId,
          });
        });
 
        this.client.on('disconnect', (reason: string) => {
          console.log('Disconnected from WebSocket server:', reason);
          this.totalReconnectCount++; // Increment totalReconnectCount on disconnect
          console.log(`Total Reconnect Count: ${this.totalReconnectCount}`); 

          if (this.totalReconnectCount >= this.maxTotalReconnects) {
            this.isDisconnectedPermanently = true;
            if (this.client) {
              this.client.removeAllListeners();
              this.client.disconnect();
              this.client.close(); // Force close the connection
            }
            if (this.pingInterval) {
              clearInterval(this.pingInterval);
              this.pingInterval = null;
            }
            //this.emitError('Session timeout. Please start a new chat');
            console.error('Max total reconnect attempts reached. Giving up.');

          // Handle session expiration here (e.g., redirect to login)
          this.client?.disconnect(); // Disconnect the client
          // Signal the UI about the error
          this.emitError('Session timeout. Please start a new chat');
           return; // Stop further reconnection attempts
          }
        
        // Reset reconnectAttempts ONLY if the total reconnect limit is NOT reached
        if (this.totalReconnectCount < this.maxTotalReconnects) {
          this.reconnectAttempts++;
          if (this.reconnectAttempts <= this.maxReconnectAttempts) {
            console.log(`Attempting to reconnect (attempt ${this.reconnectAttempts})`);
            const jitter = Math.random() * 500; // Jitter range (0-500 milliseconds)
            setTimeout(() => {
              if (!this.isDisconnectedPermanently) { //
              this.initialize(); // Attempt to reconnect
              }
            }, this.reconnectDelay + jitter); // Add jitter to the delay
            this.reconnectDelay = Math.min(this.reconnectDelay * 2, this.maxReconnectDelay); // Double the delay
          } else {
            console.error('Max reconnect attempts reached. Giving up.');  
            this.emitError('We are experiencing a temporary connection issue. Please try refreshing the page or coming back later.');

          }
        } else {
          console.error('Max total reconnect attempts reached. Giving up.');
          this.emitError('Session Timeout.Please start a new chat or try again');
        }      
      });

     
        this.client.on('message', (payload: unknown) => {
          console.log('Message from server:', payload);
        });
 
        this.client.on('trackingId', (trackingData: { trackingId: string; payload: { metadata: { eventType: string } } }) => {

          this.trackingId = trackingData.trackingId;
          console.log('Received Tracking ID:', trackingData);
          
          if (this.trackingId) {
            this.client?.emit('subscribe', {
              apiKey: process.env.REACT_APP_PUBLISH_AND_SUBSCRIBE_API_KEY,
              event: trackingData.payload.metadata.eventType,
              trackingId: trackingData.trackingId,
            });
            
          }
          });

       // // Listen for RAG operation responses
       this.client.on('RAG_AI_Backend_Operation', (payload: any) => {
        if (this.trackingId && payload.trackingId === this.trackingId) {
          // Only forward to listeners if tracking IDs match
          this.listeners.forEach((callback) => callback(payload));
          console.log('RAG_AI_Backend_Operation RAG_AI_Backend_Operation RAG_AI_Backend_Operation:', payload);
          
        }
       
      });

       
      } catch (error) {
        console.error('WebSocket initialization failed:', (error as Error).message);
        this.emitError('Network Error.Plaese try again');
        reject(error);
      }
      
    });
    
  }

  /**
   * Publishes a message to the WebSocket server.
   * @param {any} eventData - The data to be sent.
   */
  public async publishMessage(eventData: EventData): Promise<void> {
   if (this.isDisconnectedPermanently) {
      this.emitError('Session closed. Please start a new chat');
      return;
    }

    if (!this.client || !this.client.connected) {
      this.queueMessage(eventData);
      console.warn('WebSocket is not connected. Attempting to reconnect...');
      try {
        await this.initialize();
        // Check connection status immediately after successful initialization
        if (!this.client?.connected) {
          console.error('Failed to reconnect to WebSocket');
          this.emitError('Failed to reconnect to server. Refresh a page');
          return;
        }
      } catch (error) {
        console.error('Failed to reconnect to WebSocket:');
        // Display a user-friendly error 
        this.emitError('Failed to reconnect to server. Refresh a page');
        return;
      }
    }


    try {
      //this.backupRequest = eventData;
      this.client.emit('publish', {
        apiKey: process.env.REACT_APP_PUBLISH_AND_SUBSCRIBE_API_KEY,
        eventData,
      });
      console.log('Message published successfully');
      console.log('Payload:',eventData.payload);
    } catch (error) {
      console.error('Failed to send message:', (error as Error).message);
      this.emitError('There is issue in the backend. Please try again or contact admin');

    }

   
  }

  /**
   * Subscribes to a WebSocket event.
   * @param {string} event - The event name.
   * @param {(data: any) => void} callback - The callback function for handling event data.
   */
  public subscribeToEvent(event: string, data:any,callback: (data: any) => void): void {
    if (!this.client) {
      console.error('WebSocket client is not initialized');
      return;
    }

    try {
      console.log('Subscribing to event:', event);

      // Subscribe to the tracking ID event
      this.client.emit(event, {
        apiKey: process.env.REACT_APP_SUBSCRIBE_ONLY_API_KEY,
        ...data
      });
   
    } catch (error) {
      console.error('Subscription error:', (error as Error).message);
    }
  }


  /**
   * Checks if there are any listeners for a specific event.
   * @param {string} event - The event name.
   * @returns {boolean} True if listeners exist, false otherwise.
   */
 

  public addListener (callback:any) {
    this.listeners.push(callback);
  };

  private errorListeners: Array<(error: string) => void> = [];
  public emitError(error: string) {
    // Call each listener function with the error message
    this.errorListeners.forEach(listener => listener(error));
    // Clear the error listeners after emitting the error
    this.errorListeners = []; // This is the key change
  }
  public addErrorListener(listener: (error: string) => void) {
    this.errorListeners.push(listener);
  }

  
}








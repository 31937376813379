import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css"; // Includes Tailwind and global styles
import App from "./App"; // Ensure this points to App.tsx or App.js, whichever applies

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement // Type assertion to ensure "root" is not null
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

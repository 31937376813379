import React, { useState} from "react";
import ChatbotPopup from "../ChatbotPopup/ChatbotPopup";
import chatIcon from "../../assets/Button.svg"

const ChatFloating: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false); // Explicitly typed state
  

 
const handleToggle = () =>{
  //console.log('isOpenFlag',isOpen)
  if(!isOpen){
    sendDataToParent('chatbot-open')
    setIsOpen(true)
  }
  else{
    sendDataToParent('chatbot-closed')
    setIsOpen(false)
  }
}
const sendDataToParent = (data :any) => {
        const info = {
            message: "Last updated date-time",
            data
        };
      //   console.log('isOpenFlag ??',info)
        // Sending data to the parent window using postMessage
        if (data) {
           window.parent.postMessage(info, '*');

        } 
    };

    return (
      <div className="fixed bottom-5 right-5 relative group">
        {/* Chat Icon Button */}
        <div className="fixed bottom-5 right-5 flex items-center">
          <img
            src={chatIcon}
            alt="chat Icon"
            className="w-12 h-12 cursor-pointer shadow-md transition duration-300 rounded-full"
            onClick={handleToggle}
            
          />
  
      </div>
  
        {/*chatbot popup */}
        {isOpen && <ChatbotPopup onClose={() => handleToggle()} />}
      </div>
    );
  };
  
  export default ChatFloating;